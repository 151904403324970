import { mergeOverrides, useStyletron } from 'baseui';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import {
  AuthErrorMessages,
  CustomAuthErrors,
  isValidEmailFormat,
  MarketingUrlMap,
  newHash,
  RouteEnum,
  SUBSCRIPTIONS,
} from '@benefeature/shared-common';
import { FormEvent, useEffect, useState } from 'react';
import { Block } from 'baseui/block';
import {
  BaseWebHelpers,
  BeButtonLink,
  BeCard,
  BeInput,
  BeSpinnerMessage,
  BeStyledOrHr,
  BeTooltip,
  grayPopoverOverrides,
} from '@benefeature/shared/ui';
import {
  HeadingMedium,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelXSmall,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const isValidNameFormat = (name: string) => {
  return name && name.length > 3 && name.split(' ').length > 1;
};

const isValidTeamNameFormat = (teamName: string) => {
  return teamName && teamName.length >= 3;
};

const getValidationResults = (
  name,
  teamName,
  email,
  isSignup = false
): Record<'name' | 'teamName' | 'email', boolean> => {
  return {
    name: isSignup ? isValidNameFormat(name) : true,
    teamName: isSignup ? isValidTeamNameFormat(teamName) : true,
    email: isValidEmailFormat(email),
  };
};

export function LoginOrSignup({ isSignup = false }) {
  const [, /*css*/ $theme] = useStyletron();
  const router = useRouter();

  const { data: session, status: sessionStatus } = useSession();

  const [name, setName] = useState('');
  const [teamName, setTeamName] = useState('');
  const [email, setEmail] = useState('');

  // Track which fields have been touched
  const [touchedFields, setTouchedFields] = useState<Record<'name' | 'teamName' | 'email', boolean>>({
    name: false,
    teamName: false,
    email: false,
  });

  // After each value is updated, update the validation results as well (BFR-1051)
  const [validationResults, setValidationResults] = useState<Record<'name' | 'teamName' | 'email', boolean>>(
    getValidationResults(name, teamName, email, isSignup)
  );

  // If this is a redirect from the sign up page due to the account existing and there was a provided email,
  // populate it automatically in the email address field for user convenience (BFR-881)
  useEffect(() => {
    if (
      router.isReady &&
      CustomAuthErrors.AccountExists === router.query?.error &&
      !isSignup &&
      router.query?.providedEmail
    ) {
      setEmail(router.query?.providedEmail as string);
    }
  }, [router.isReady, router.query, isSignup]);

  // Define the redirect target
  // Encode the current redirect target (or search) as the passed along redirect property
  // and send to the complete setup
  // This target should be used regardless of whether the user is signing in or signing up
  // Add the price ID if one was provided in the query params, used for sign up flow (BFR-
  const authSuccessRedirectTarget = `${RouteEnum.AUTH_ACCOUNT_SETUP}?redirect=${encodeURIComponent(
    (router.query.redirect as string) || RouteEnum.SEARCH
  )}${router.query.price_id ? `&price_id=${router.query.price_id as string}` : ''}`;
  useEffect(() => {
    if (router.isReady && sessionStatus === 'authenticated' && session?.user?.id) {
      router.push(authSuccessRedirectTarget);
    }
  }, [router, sessionStatus, session, authSuccessRedirectTarget]);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Update validations
    setValidationResults(getValidationResults(name, teamName, email, isSignup));

    // Only run submit if validation is good
    if (Object.values(validationResults).every((res) => res === true)) {
      // Mark as loading and attempt sign in (or sign up)
      setIsLoading(true);

      await signIn(
        'email',
        { callbackUrl: authSuccessRedirectTarget, email: email?.toLowerCase() },
        // Leveraging authorizationParams, pass the signUpToken if this is the sign up page
        // Also include the name and teamName values (BFR-1051)
        // These values can be parsed from the request query within the signIn method from the NextAuth API
        {
          ...(isSignup
            ? {
                signUpToken: newHash(null, email),
                name: name,
                teamName: teamName,
              }
            : {}),
        }
      );
    }
  };

  return (
    <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
      {sessionStatus === 'loading' ? (
        <BeSpinnerMessage message={'Loading...'} />
      ) : (
        <BeCard overrides={{ Root: { style: { maxWidth: '475px' } } }}>
          <Block
            maxWidth={'475px'}
            paddingTop={'30px'}
            paddingBottom={'30px'}
            paddingLeft={'20px'}
            paddingRight={'20px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Block width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <img
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                src={`${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/benefeature/logo-icon.svg`}
                alt="Benefeature icon"
              />
            </Block>

            <Block width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <HeadingMedium>{isSignup ? `Sign up for access` : 'Sign in'}</HeadingMedium>

              {/* Handle specific sign in error display scenarios here
                      See https://next-auth.js.org/configuration/pages#sign-in-page
                      A common scenario will be AccountDisabled, an internal error thrown when user.is_active is false */}
              {router.query?.error ? (
                <Block marginBottom={'40px'}>
                  <BeCard>
                    <ParagraphMedium color={$theme.colors.contentNegative}>
                      {AuthErrorMessages[router.query?.error as string] || AuthErrorMessages['Default']}
                      {/* No existing account was found during sign in, redirected automatically to sign up */}
                      {CustomAuthErrors.NoExistingAccount === router.query?.error && isSignup ? (
                        <>
                          <br />
                          <br />
                          You have been redirected to the sign up page, but if the email below was incorrect, return to
                          sign in and try again.
                        </>
                      ) : null}
                      {/* An existing account was found during sign up, redirected automatically to sign in */}
                      {CustomAuthErrors.AccountExists === router.query?.error && !isSignup ? (
                        <>
                          <br />
                          <br />
                          You have been redirected to complete login
                          {router.query?.providedEmail ? ' with the following email:' : '.'}
                        </>
                      ) : null}
                      {}
                      {router.query?.providedEmail ? (
                        <LabelLarge marginTop={'12px'} color={$theme.colors.contentNegative}>
                          {router.query?.providedEmail}
                        </LabelLarge>
                      ) : null}
                      <br />
                      Please contact support for more information or assistance.
                    </ParagraphMedium>
                  </BeCard>
                </Block>
              ) : null}

              <Block>
                <BeButtonLink
                  href={
                    // Use the raw redirect value when transitioning between sign in/sign up, not the authSuccessRedirectTarget value
                    isSignup
                      ? `${RouteEnum.AUTH_LOGIN}?redirect=${(router.query.redirect as string) || RouteEnum.SEARCH}`
                      : `${RouteEnum.AUTH_SIGNUP}?redirect=${(router.query.redirect as string) || RouteEnum.SEARCH}`
                  }
                  kind={'tertiary'}
                  size={'mini'}
                >
                  {isSignup
                    ? `Sign in if you already have ${SUBSCRIPTIONS.PRO} or ${SUBSCRIPTIONS.ENTERPRISE} access`
                    : `No account yet? Sign up for Pro or Enterprise to get access`}
                  {' >'}
                </BeButtonLink>
              </Block>

              {isSignup ? (
                <LabelMedium marginTop={'40px'}>
                  Creating an account provides consent
                  <br />
                  to our{' '}
                  <a target={'_blank'} href={MarketingUrlMap.TERMS_OF_SERVICE}>
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a target={'_blank'} href={MarketingUrlMap.PRIVACY_POLICY}>
                    Privacy Policy
                  </a>
                </LabelMedium>
              ) : null}

              <Block
                width={'max(300px, 100%)'}
                marginTop={'48px'}
                display={'flex'}
                flexDirection={'column'}
                gridRowGap={'16px'}
                alignItems={'flex-start'}
              >
                <ParagraphSmall
                  marginTop={0}
                  marginBottom={0}
                  color={$theme.colors.contentTertiary}
                  alignSelf={'center'}
                >
                  Email authentication
                </ParagraphSmall>

                {isSignup ? (
                  <>
                    <Block width={'100%'}>
                      <LabelSmall>Your Name</LabelSmall>
                      <BeInput
                        id="name"
                        type={'text'}
                        disabled={isLoading}
                        positive={touchedFields.name && name && validationResults.name}
                        error={touchedFields.name && name && !validationResults.name}
                        value={name}
                        onChange={(e: any) => setName(e.target.value)}
                        onBlur={() => {
                          setValidationResults(getValidationResults(name, teamName, email, isSignup));
                          setTouchedFields({ ...touchedFields, name: true });
                        }}
                        onKeyUp={(e) =>
                          e.key === 'Enter' &&
                          !isLoading &&
                          Object.values(validationResults).every((res) => res === true)
                            ? onSubmit(e)
                            : null
                        }
                        required
                        isAccented
                      />
                      {touchedFields.name && name && !validationResults.name ? (
                        <LabelXSmall color={$theme.colors.contentNegative}>
                          Please enter a valid full name (first and last)
                        </LabelXSmall>
                      ) : null}
                    </Block>

                    <Block width={'100%'}>
                      <LabelSmall>Team Name</LabelSmall>
                      <BeInput
                        id="team-name"
                        type={'text'}
                        disabled={isLoading}
                        positive={teamName && validationResults.teamName}
                        error={teamName && !validationResults.teamName}
                        value={teamName}
                        onChange={(e: any) => setTeamName(e.target.value)}
                        onBlur={() => {
                          setValidationResults(getValidationResults(name, teamName, email, isSignup));
                          setTouchedFields({ ...touchedFields, teamName: true });
                        }}
                        onKeyUp={(e) =>
                          e.key === 'Enter' &&
                          !isLoading &&
                          Object.values(validationResults).every((res) => res === true)
                            ? onSubmit(e)
                            : null
                        }
                        required
                        isAccented
                      />
                      <LabelXSmall color={$theme.colors.contentTertiary}>
                        Usually your company's name and your division
                      </LabelXSmall>
                      {touchedFields.teamName && teamName && !validationResults.teamName ? (
                        <LabelXSmall color={$theme.colors.contentNegative}>
                          Please enter a valid team name with at least 3 characters
                        </LabelXSmall>
                      ) : null}
                    </Block>
                  </>
                ) : null}

                <Block width={'100%'}>
                  <LabelSmall>{isSignup ? 'Your ' : null}Email Address</LabelSmall>
                  <BeInput
                    id="email"
                    type={'email'}
                    disabled={isLoading}
                    positive={email && validationResults.email}
                    error={email && !validationResults.email}
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value?.toLowerCase())}
                    onBlur={() => {
                      setValidationResults(getValidationResults(name, teamName, email, isSignup));
                      setTouchedFields({ ...touchedFields, email: true });
                    }}
                    onKeyUp={(e) =>
                      e.key === 'Enter' && !isLoading && Object.values(validationResults).every((res) => res === true)
                        ? onSubmit(e)
                        : null
                    }
                    required
                    isAccented
                  />
                  {touchedFields.email && email && !validationResults.email ? (
                    <LabelXSmall color={$theme.colors.contentNegative}>Please enter a valid email address</LabelXSmall>
                  ) : null}
                </Block>
              </Block>

              <Block
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                gridRowGap={'6px'}
                marginBottom={'32px'}
              >
                <Block
                  paddingTop={'12px'}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  gridColumnGap={'24px'}
                >
                  <BeButtonLink
                    kind={'primary'}
                    onClick={(e) => onSubmit(e)}
                    disabled={isLoading || !(touchedFields.email && email && validationResults.email)}
                  >
                    Send Verification Link
                  </BeButtonLink>
                </Block>

                <BeTooltip
                  overrides={{
                    ...mergeOverrides(grayPopoverOverrides, {
                      Inner: {
                        style: { maxWidth: '300px', ...BaseWebHelpers.padding('12px') },
                      },
                      Body: {
                        style: {
                          // Offset manually with this margin
                          ...BaseWebHelpers.margin('16px'),
                        },
                      },
                    }),
                  }}
                  content={
                    <>
                      A Verification Link, also known as a "magic link", is a special link sent directly to your email
                      which confirms you own the account.
                      <br />
                      <br />
                      When that link is clicked it will open a new page with your user logged into the application.
                    </>
                  }
                >
                  <LabelXSmall
                    style={{ cursor: 'default', userSelect: 'none' }}
                    color={$theme.colors.contentTertiary}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <InfoOutlinedIcon fontSize={'small'} />
                    What is this?
                  </LabelXSmall>
                </BeTooltip>
              </Block>

              <BeStyledOrHr />

              <ParagraphSmall marginTop={0} marginBottom={0} color={$theme.colors.contentTertiary}>
                Social authentication
              </ParagraphSmall>

              <Block
                marginTop={'20px'}
                display={'flex'}
                flexDirection={'row'}
                flexWrap={true}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Block marginLeft={'12px'} marginRight={'12px'} marginTop={'8px'} marginBottom={'24px'}>
                  <BeButtonLink
                    kind={'primary'}
                    size={'large'}
                    startEnhancer={<GoogleIcon />}
                    disabled={isLoading}
                    overrides={{
                      BaseButton: {
                        style: {
                          backgroundColor: '#4084f4',
                          ':hover': {
                            backgroundColor: '#3874d7',
                          },
                        },
                      },
                    }}
                    onClick={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        signIn('google', { callbackUrl: authSuccessRedirectTarget });
                      }
                    }}
                  >
                    Google
                  </BeButtonLink>
                </Block>
                <Block marginLeft={'12px'} marginRight={'12px'} marginTop={'8px'} marginBottom={'24px'}>
                  <BeButtonLink
                    kind={'primary'}
                    size={'large'}
                    startEnhancer={<LinkedInIcon />}
                    disabled={isLoading}
                    overrides={{
                      BaseButton: {
                        style: {
                          backgroundColor: '#007bb5',
                          ':hover': {
                            backgroundColor: '#016a9b',
                          },
                        },
                      },
                    }}
                    onClick={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        signIn('linkedin', { callbackUrl: authSuccessRedirectTarget });
                      }
                    }}
                  >
                    LinkedIn
                  </BeButtonLink>
                </Block>
              </Block>

              {isLoading ? (
                <BeSpinnerMessage
                  ContainerComponent={HeadingMedium}
                  message={<LabelLarge>Loading authentication...</LabelLarge>}
                />
              ) : null}
            </Block>
          </Block>
        </BeCard>
      )}
    </Block>
  );
}
